import type { AxiosInstance } from 'axios';
import axios from 'axios';

import { handleError } from './errorHandlers';

const getInstanceApi = (): AxiosInstance => {
    const api = axios.create({
        timeout: 10000,
        withCredentials: true,
    });

    api.interceptors.response.use((response) => response, handleError());

    return api;
};

export const api = getInstanceApi();
