enum ENVIRONMENT {
    PRODUCTION = 'production',
    DEVELOPMENT = 'development',
    LOCAL = 'local',
}

enum TRANSLATION {
    RU = 'ru',
    EN = 'en',
}

export { ENVIRONMENT, TRANSLATION };

export type TGuid = string;

export type TNullable<T> = T | null | undefined;

export type RecursivePartial<T> = T extends object
    ? {
          [P in keyof T]?: T[P] extends (infer U)[]
              ? RecursivePartial<U>[]
              : T[P] extends object
                ? RecursivePartial<T[P]>
                : T[P];
      }
    : any;

export type RequiredField<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type TTKey = string;

export type TIdParam = { id: string };
